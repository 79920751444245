import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from '../DangerouslySetInnerHtml';
import { ILabel } from './models';
import './Label.scss';

const Label: FC<ILabel> = ({ children, type, html }) => {
  const labelClasses = classNames('dt-label', {
    [`dt-label--${type}`]: type,
  });

  if (html) return <DangerouslySetInnerHtml element="span" html={html} className={labelClasses} />;

  return <span className={labelClasses}>{children}</span>;
};

export default Label;
