import React, { FC, ReactElement } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Card from 'react-bootstrap/Card';

import Label from 'components/common/Label';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { ICardBox } from './models';
import './CardBox.scss';

const CardBox: FC<ICardBox> = ({ image, imageAlt, title, link, intro, labelTop, large }) => {
  const labelItem = labelTop ? <Label type={labelTop.type}>{labelTop.children}</Label> : null;
  const classes = classNames('dt-card-box', {
    'dt-card-box--large': large,
  });

  const introItem = intro ? <Card.Text>{intro}</Card.Text> : null;

  let ImageComponent: ReactElement | null = null;
  if (image && image.fluid) {
    ImageComponent = <UmbracoImage image={image} alt={imageAlt ?? title} />;
  } else if (image && image.fallbackUrl) {
    ImageComponent = (
      <div className="dt-umbraco-image">
        <picture>
          <source srcSet={image.fallbackUrlWebp} type="image/webp" />
          <source srcSet={image.fallbackUrl} type="image/jpeg" />
          <img height="100%" width="100%" data-src={image.fallbackUrl} alt={imageAlt ?? title} />
        </picture>
      </div>
    );
  }

  return (
    <div className={classes}>
      <Link to={link}>
        <Card>
          {labelItem}
          {ImageComponent}
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            {introItem}
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};

export default CardBox;
